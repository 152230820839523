import { Link } from 'react-router-dom';

export default function HelpContent() {
    return (
        <div id="dg-outer-wrapper">
            <div id="dg-wrapper">
                <div className="dg-container">
                    <section id="mainTab" className="dg-content dg-how-to-start active">
                        <ul className="dg-help">
                            <li>
                                <div className="help-content">
                                    <h1>Alle Frequenzen und Informationen zu Radio und TV</h1>
                                    <p>Einfach Gemeindename oder PLZ eingeben oder Ihren Standort automatisch <img src="img/gps-icon-gray.png" alt=''/> ermitteln und suchen …</p>
                                    <ul>
                                        <li style={{"textAlign": "right"}}><Link to="/hilfe">Hilfe &amp; mehr …</Link></li>
                                    </ul>
                                </div>
                            </li>
                            <div className="clear"></div>
                        </ul>
                    </section>
                </div>
            </div>
        </div>
    )
}
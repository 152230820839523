/* eslint-disable jsx-a11y/anchor-is-valid */
import ReceivePathDescription from './receivePathDescription';
import ReceivePathsViewMobile from './receivePathsViewMobile';

export default function ReceivePaths(props) {
  const getIndexForId = (services, id) => {
    let index = services.findIndex(service => service.id === id);
    return index === -1 ? 0 : index;
  }

  const getIndexForCurrentlySelectedService = () => {
    return getIndexForId(props.services, props.selectedServiceId)
  }

  return <>
    <ul className="dg-nav nav">
      {props.services.map((service, index) => (
        <li key={index} className={"dg-" + service.receivePath.toLowerCase() + (
          getIndexForCurrentlySelectedService() === index
            ? " active dg-active"
            : ""
          )}
          onClick={() => props.setSelectedServiceId(service.id)}>
          <a className="dg-media-button" title={service.networkParameters[0].receiveTooltip}>
            {service.receivePath}
            <span title={service.networkParameters[0].serviceTooltip}
              dangerouslySetInnerHTML={{"__html": service.serviceType}} style={{"whiteSpace": "nowrap"}} />
          </a>
        </li>
      ))}
    </ul>

    <div className="dg-tab-content">
      <div className={"tab-pane active dg-" + props.services[getIndexForCurrentlySelectedService()].receivePath.toLowerCase()}>
        <ul>
          {props.services[getIndexForCurrentlySelectedService()].networkParameters.map((parameters, index) => (
            <li key={index}>
              <ReceivePathDescription parameters={parameters}/>
            </li>
          ))}
        </ul>
      </div>
    </div>
    <ReceivePathsViewMobile
      setSelectedServiceId={props.setSelectedServiceId}
      getIndexForCurrentlySelectedService={getIndexForCurrentlySelectedService}
      {...props}
    />
    <div className="dg-media-desc">
        <span dangerouslySetInnerHTML={{'__html': props.programMeta.slogan}}/>
        <p className="dg-long dg-panel" dangerouslySetInnerHTML={{'__html': props.programMeta.description}}></p> 
    </div>
  </>
}

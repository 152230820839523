/* eslint-disable jsx-a11y/anchor-is-valid */
export default function regionSwitchMobile(props) {
    return (
        <>
        <div style={{display: props.active?'':'none'}} className="vis-mobile">
            <button className={"dg-regio-free " + (props.showRegion === -1 ? 'dg-active active' : '')}>
                <a href="#" onClick={() => props.setShowRegion(-1)}>Alle</a>
            </button>
            { props.program.regionsWithServices.map((region,index) => {
                return (
                    <button key={index} className={"dg-regio-free" + (props.showRegion === index ? ' dg-active active' : '')}>
                        <a href="#" onClick={() => props.setShowRegion(index)}>{region.region}</a>
                    </button>
                )
            })}
        </div>
        <div style={{clear: 'both'}}/>
        </>
    )
}

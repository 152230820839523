export default function ReceivePathDescription(props) {
  return (
    <ul className="dg-parameters">
      <li>
        <div className="dg-parameters-content">
          {props.parameters.networkName !== null &&
            <div className="dg-networkName" dangerouslySetInnerHTML={{"__html": props.parameters.networkName}}/>}
          {props.parameters.networkInfo &&
            <div className="dg-networkInfo" dangerouslySetInnerHTML={{"__html": props.parameters.networkInfo}}/>}
          <div className="dg-description" dangerouslySetInnerHTML={{"__html": (Array.isArray(props.parameters.description)
            ? props.parameters.description.join(", ")
            : props.parameters.description)}}/>
        </div>
        <div className="dg-quality">
          {props.parameters.picture !== undefined &&
            <img src={"/img/" + props.parameters.picture} width={119} height={25} title={props.parameters.tooltip} alt="" />}
        </div>
      </li>
    </ul>
  )
}

import { useEffect } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { bindKeyboard } from 'react-swipeable-views-utils';
import ProgramSelection from './programSelection';
import ProgramView from './programView';

const BindKeyboardSwipeableViews = bindKeyboard(SwipeableViews);

export default function ProgramsView(props) {
  useEffect(() => {
    if(props.programAndTypeDeeplinked === true) {
      return;
    }

    props.setProgramAndTypeDeeplinked(true);

    const deepLinkedProgram = new URLSearchParams(window.location.search).get('programm');
    const deepLinkedServiceType = new URLSearchParams(window.location.search).get('type');

    if (deepLinkedProgram?.length > 0) {
      let index = props.locationMetadata.programs.findIndex(program =>
        program.program.toLowerCase() === deepLinkedProgram.toLowerCase());
      if (index > 0) {
        props.setCurrentProgramIndex(index);
      }
    };

    if (deepLinkedServiceType?.length > 0) {
      let index = props.locationMetadata.programs[props.currentProgramIndex].services.findIndex(service =>
        service.serviceType.toLowerCase() === deepLinkedServiceType.toLowerCase());
      if (index >= 0) {
        props.setSelectedServiceId(props.locationMetadata.programs[props.currentProgramIndex].services[index].id);
      }
    };
  // eslint-disable-next-line
  }, []); // Empty array as dependency so that this code is only executed once

  return (
    <div>
      <ProgramSelection
        locationMetadata={props.locationMetadata}
        currentProgramIndex={props.currentProgramIndex}
        setCurrentProgramIndex={props.setCurrentProgramIndex}
        nextProgram={props.nextProgram}
        previousProgram={props.previousProgram}
        />
      <div id="dg-outer-wrapper">
        <div id="dg-wrapper" className="dg-wrapper">
          <div className="dg-container">
              <section className="dg-content dg-how-to-start active" id="mainTab">
                <div className="swiper-container" id="dg-swiper-1">
                  <div className="swiper-wrapper">
                    <div className="swiper-slide dg-media swiper-slide-visible swiper-slide-active">
                      <BindKeyboardSwipeableViews enableMouseEvents
                          onChangeIndex={(index) => {props.setCurrentProgramIndex(index);}}
                          index={props.currentProgramIndex}>
                        {props.locationMetadata.programs.map((program,index) => (
                          <ProgramView place={props.place} nextProgram={props.nextProgram} active={(props.currentProgramIndex === index)} key={program.id} program={program} location={props.location} selectedServiceId={props.selectedServiceId} setSelectedServiceId={props.setSelectedServiceId}/>
                        ))}
                      </BindKeyboardSwipeableViews>
                    </div>
                  </div>
                </div>
              </section>
          </div>
        </div>
      </div>
      {/*{ JSON.stringify(props.location) }*/}
    </div>
  );
}

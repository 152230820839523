import ReceivePathDescription from './receivePathDescription';

export default function receivePathsViewMobile(props) {
    return (
        <div className="dg-tab-content dg-accordion">
            {props.services.map((service, index) => {
                return (
                    <div key={index}>
                      <div
                          className={"dg-acc" + (props.getIndexForCurrentlySelectedService() === index ? " dg-active" : "")}
                          onClick={() => props.setSelectedServiceId(service.id)}
                          >
                          { /* eslint-disable-next-line jsx-a11y/anchor-is-valid */ }
                          <a>{service.receivePath}<span dangerouslySetInnerHTML={{'__html': service.serviceType}}></span></a>
                      </div>
                      <div className={
                          "collapse-pane dg-" + service.receivePath.toLowerCase()
                          + (props.getIndexForCurrentlySelectedService() === index ? " dg-active" : "")}>
                      <ul>
                          {service.networkParameters.map((parameters, index) => (
                              <li key={index}>
                                  <ReceivePathDescription parameters={parameters}/>
                              </li>
                          ))}
                          </ul>
                      </div>
                    </div>
                )
            })}
        </div>
    )
}

export default function Help(props) {
  props.setNewLocationSearchActive(false);
  return (
    <div id="dg-outer-wrapper">
      <div id="dg-wrapper" className="dg-wrapper">
        <div className="dg-container">
          <section
            className="dg-content dg-how-to-info active"
            data-dg="dg-show-information"
            id="infoTab"
          >
            <ul className="dg-help">
              <li>
                <div className="help-content">
                  <h1>So bedienen Sie die Frequenzsuche</h1>
                  <h3>Den BR empfangen</h3>
                  <p>
                    Die Suche liefert für einen beliebigen Standort in Deutschland die
                    empfangbaren BR-Programme und ihre Empfangsparameter.
                  </p>
                </div>
                <div className="help-arrow"></div>
              </li>
              <li>
                <div className="help-content">
                  <h3>Gemeindenamen eingeben</h3>
                  <p>
                    Tragen Sie einen Gemeindenamen oder eine PLZ in Deutschland in die
                    Suchmaske ein oder lassen Sie Ihren Standort automatisch
                    bestimmen.
                  </p>
                </div>
                <div className="help-arrow"></div>
              </li>
              <li>
                <div className="help-content">
                  <h3>Programm auswählen</h3>
                  <p>
                    Wählen Sie aus den empfangbaren BR-Programmen per Logo oder durch
                    Blättern aus. Teilweise stehen auch Regionalvarianten zur Auswahl.
                  </p>
                </div>
                <div className="help-arrow"></div>
              </li>
              <li className="last">
                <div className="help-content">
                  <h3>Empfangsart wählen</h3>
                  <p>
                    Wählen Sie eine verfügbare Empfangsart und Sie erhalten die
                    wichtigsten Angaben. Fehlt eine Empfangsart, ist sie bzw. das
                    Programm an dem gewünschten Standort nicht verfügbar.
                  </p>
                </div>
              </li>
            </ul>
            <ul className="dg-help">
              <li className="last">
                <div className="help-content help-coverage">
                  <h1>Das bedeuten die Symbole</h1>
                  <h3>Empfangsbedingungen</h3>
                  <p>
                    <span className="antena_help">
                      Zum Empfang ist eine für den Frequenzbereich geeignete
                      Dachantenne erforderlich.
                    </span>{" "}
                  </p>
                  <p>
                    <span className="outdoor_help">
                      Der Empfang ist im Freien oder mobil unter Verwendung einer
                      geeigneten Antenne (Außenantenne) möglich.
                    </span>{" "}
                  </p>
                  <p>
                    <span className="indoor_help">
                      Der Empfang ist innerhalb von Gebäuden mit einer geeigneten
                      Antenne (Zimmerantenne) möglich.
                    </span>
                  </p>
                  <br style={{ clear: "both" }} />
                </div>
              </li>
            </ul>

            <ul className="dg-help">
              <li className="last">
                <div className="help-content">
                  <h1>Kontakt</h1>
                  <p>Sie haben Fragen zur Frequenzsuche? So erreichen Sie uns:</p>
                  <p>
                    <a href="https://www.br.de/unternehmen/service/kontakt/bayerischer-rundfunk-kontakt-radio-hoerfunk-e-mail-telefon102.html?ssl=true" rel="noreferrer" target="_blank">
                      Technische Information des Bayerischen Rundfunks
                    </a>
                  </p>
                </div>
              </li>
            </ul>

            <ul className="dg-help">
              <li className="last">
                <div className="help-content">
                  <h1>Impressum</h1>
                  <p>
                    <a href="https://www.br.de/unternehmen/service/impressum/index.html" rel="noreferrer" target="_blank">
                      www.br.de/impressum
                    </a>
                  </p>
                </div>
              </li>
            </ul>
          </section>
        </div>
      </div>
    </div>
  );
}

import ProgramNavigationImage from './programNavigationImage';

export default function ProgramSelection(props) {

  return (
    <nav id="dg-subnav" className="dg-subnav isNotEmpty" style={{display: "block"}}>
      <div className="dg-swiper-controls dg-container">
        <div className="dg-subnav-controls dg-left">
          { /* eslint-disable-next-line jsx-a11y/anchor-is-valid */ }
          <a className="swiper-control" onClick={props.previousProgram}> 
            ‹
          </a>
        </div>
          <div className="dg-subnav-info">
            <div className="dg-indicators" data-dg="dg-insert-indicators">
              <div data-dg="swiper-pagination" className="swipe-indicators">
                {props.locationMetadata.programs.map((item, index) => (
                  <ProgramNavigationImage key={index} item={item} index={index} setCurrentProgramIndex={props.setCurrentProgramIndex} active={props.currentProgramIndex === index}/>
                ))}
              </div>
            </div>
          </div>
        <div className="dg-subnav-controls dg-right">
          { /* eslint-disable-next-line jsx-a11y/anchor-is-valid */ }
          <a className="swiper-control" onClick={props.nextProgram}>
            ›
          </a>
        </div>
      </div>
    </nav>
  );
}

import { useState } from "react";
import { AsyncTypeahead, Menu, MenuItem } from 'react-bootstrap-typeahead';
import Fade from 'react-reveal/Fade';


export default function FindLocation(props) {
  const [search, setSearch] = useState("");
  const [isGpsInfo, setIsGpsInfo] = useState(true);
  const [lookupError, setLookupError] = useState(false);
  const [searchAlertText, setSearchAlertText] = useState("");
  const [showSearchAlertBox, setShowSearchAlertBox] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [activeIndex, setActiveIndex] = useState(-1);
  const filterBy = () => true;

  const highlight = (zipForDisplay) => {
    const match_re = new RegExp(`^(${search})`, 'i');
    return zipForDisplay.replace(match_re, "<b>$1</b>");
  }

  const showSearchAlert = (alertText) => {
    setShowSearchAlertBox(true);
    setSearchAlertText(alertText);
  }

  const hideSearchAlert = () => {
    setShowSearchAlertBox(false);
    setSearchAlertText("");
  }

  const updateSearch = (query) => {
    isGpsInfo && setIsGpsInfo(false);
    setSearch(query);
    if (query.length > 1) {
      fetch(props.endpoint + "/api/places?prefix=" + query)
        .then((data) => data.json())
        .then((data) => {
          setSuggestions(data);
          if (data.length === 0) {
            if (!isNaN(query) && (query.length > 5)) {
              showSearchAlert("Die angegebene Postleitzahl ist ungültig. Bitte geben Sie eine gültige, fünfstellige Postleitzahl ein.");
            } else {
              showSearchAlert("Zu diesem Ort konnte leider kein Eintrag gefunden werden.");
            }
          }
          else {
            hideSearchAlert();
          }
        });
    }
    else {
      if (query.length === 0) {
        setSuggestions([]);
        hideSearchAlert();
      }
    }
  };


  const toggleGpsInfo = () => {
    setIsGpsInfo(!isGpsInfo);
  };
  const geoButtonPressed = () => {
    setSearch("");
    setSuggestions([]);
    setIsGpsInfo(false);
    getGpsInfo();
  };

  const getGpsInfo = () => {
    // API-Key ist ungültig für unsere Anwendung
    props.setModalOpen(true);
    window.navigator.geolocation.getCurrentPosition(
      p => {
        fetch('https://maps.googleapis.com/maps/api/geocode/json?result_type=postal_code&key=AIzaSyCvLas5R1ygoNgvYFAO_QN2svf1goJGh9s&latlng=' + p.coords.latitude + "," + p.coords.longitude)
        .then(data => data.json())
        .then(data => {
          if (data.status !== "OK" || data.results[0].address_components[0].types[0] !== "postal_code") {
            props.setModalOpen(false);
            setLookupError(true);
          } else {
            fetch(props.endpoint + "/api/places?prefix=" + data.results[0].address_components[0].long_name)
              .then((data) => data.json())
              .then((data) => {
                props.setLocation(data[0]);
                props.setPlace(data[0]);
                props.setNewLocationSearchActive(false);
                props.setModalOpen(false);
              });
          }
        }); 
      },
      e => {
        switch (e.code) {
          case e.PERMISSION_DENIED:
            showSearchAlert('Ihr Gerät oder Browser erlaubt keine Lokalisierung per GPS/Geolocation.');
            break;
          case e.POSITION_UNAVAILABLE:
            showSearchAlert('Positionsinformationen sind nicht verfügbar.');
            break;
          case e.TIMEOUT:
            showSearchAlert('Zeitüberschreitung bei der Positionsermittlung. Der Vorgang wurde abgebrochen.');
            break;
          default:
            showSearchAlert('Ein unbekannter Fehler ist bei der Positionsermittlung aufgetreten. Bitte wiederholen Sie Ihre Eingabe.');
            break;
        }
        props.setModalOpen(false);
      }
    );
  }

  return (
    <div>
      <Fade down>
      <section
        id="search-panel"
        className="dg-panel-search"
        data-dg="dg-search"
      >
        <div className="dg-container">
          <form className="dg-search" onSubmit={(e) => {
            e.preventDefault();
            if (suggestions.length > 0) {
              props.suggestionClicked(suggestions[0]);
            }
            else {
              showSearchAlert("Bitte geben Sie eine Postleitzahl oder einen Ort ein oder bestimmen Sie via GPS Ihre aktuelle Position.");
            }
          }}>
            <span className="twitter-typeahead">
            <AsyncTypeahead
              filterBy={filterBy}
              id="plz-search"
              delay={0}
              minLength={0}
              onSearch={updateSearch}
              options={suggestions}
              renderMenu={(results, menuProps) => {
                // Bootstrap Typeahead otherwise shows its own hints
                if (!results.length) {
                  return null;
                }

                return <Menu {...menuProps}>
                  {results.map((result, index) => (
                    <MenuItem option={result} position={index}>
                      <div className="tt-suggestion tt-selectable" onClick={() => props.suggestionClicked(result)} dangerouslySetInnerHTML={{__html: highlight(result.zipForDisplay)}}></div>
                    </MenuItem>
                  ))}
                </Menu>
              }}
              className="typeahead dg-search-item"
              inputProps={{"className": "dg-search-item"}}
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  if (suggestions.length > 0) {
                    if (activeIndex >= 0) {
                      props.suggestionClicked(suggestions[activeIndex]);
                    }
                    else {
                      props.suggestionClicked(suggestions[0]);
                    }
                  }
                  else {
                    showSearchAlert("Bitte geben Sie eine Postleitzahl oder einen Ort ein oder bestimmen Sie via GPS Ihre aktuelle Position.");
                  }
                }
              }}
              labelKey='zipForDisplay'
              placeholder="Gemeindename oder PLZ">
              {(state) => {
                setActiveIndex(state.activeIndex);
              }}
            </AsyncTypeahead>
            </span>
            {window.navigator.geolocation && (
              <div>
                <span id="geoButton">
                  <button
                    className="dg-gps"
                    data-dg="search_geoCode"
                    id="dg-gps-in"
                    type="button"
                    onClick={() => geoButtonPressed()}
                  ></button>
                </span>
                {(!props.location && isGpsInfo) && (
                  <Fade down>
                    <div data-dg="dg-info-gps" className="dg-panel-gps">
                      <div className="dg-popover">
                        <div className="arrow"></div>
                        <h3 className="dg-popover-title">
                          Ihr Gerät kann Ihre Position automatisch bestimmen!
                        </h3>
                        <div className="dg-popover-content">
                          Möchten Sie für die Frequenzsuche Ihren aktuellen
                          Standort verwenden?
                        </div>
                        <button className="dg-popover-no" type="reset" onClick={() => toggleGpsInfo()}>
                          Nein, danke
                        </button>
                        <button
                          className="dg-popover-yes"
                          data-dg="search_geoCode"
                          type="button"
                          onClick={() => {toggleGpsInfo(); getGpsInfo()}}
                        >
                          Ja, Position bestimmen
                        </button>
                      </div>
                    </div>
                  </Fade>
                )}
              </div>
            )}
            { lookupError && <div id="lookupError">Wir konnten keine Standortdaten ermitteln!</div> }
            <button
              className="dg-search-item dg-send"
              data-dg="search_button"
              id="dg-send-in"
              type="submit"
            >
              Suchen
            </button>
          </form>
        </div>
      </section>
      <section id="dg-help" className="dg-help location-search-warning">
        <div className="dg-container">
          <div className="dg-help-box" data-dg="dg-alert-box">
            { showSearchAlertBox &&
              <Fade down>
                <div className="dg-warn-info" id="warnText">
                  <h5 className="alert-heading">Hinweis - </h5>
                  <p className="alert-text">{searchAlertText}</p>
                </div>
              </Fade> }
          </div>
        </div>
      </section>
      </Fade>
    </div>
  );
}

/* eslint-disable jsx-a11y/anchor-is-valid */
import Fade from 'react-reveal/Fade';

export default function RegionSwitch(props) {

    return (
        <>
        <Fade down>
            <div style={{display: props.active?'block':'none'}} className="regioswitch dg-media-list dg-media-list-regio vis-desktop">
            <ul className="dg-regio-list">
                <li className="dg-regio-headline">
                <h4>
                    <span>weiterhin verfügbare</span>
                    Regionalprogramme
                </h4>
                </li>
                <li className={"dg-regio-free" + (props.showRegion === -1 ? ' dg-active active' : '')}>
                <a href="#" onClick={() => props.setShowRegion(-1)}>Alle</a>
                </li>
                { props.program.regionsWithServices.map((region,index) => {
                return (
                    <li key={index} className={"dg-regio-free" + (props.showRegion === index ? ' dg-active active' : '')}>
                    <a href="#" onClick={() => props.setShowRegion(index)}>{region.region}</a>
                    </li>
                )
                })}
            </ul>
            </div>
        </Fade>
        </>
    )
}

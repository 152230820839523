import "./App.css";
import Nav from "./components/nav-main-head";
import { useEffect, useState} from "react";
import FindLocation from "./components/findLocation";
import ProgramsView from "./components/programsView";
import Help from "./components/help";
import HelpContent from './components/helpContent';
import Empfangswege from "./components/empfangswege";
import TransferModal from './components/transferModal';

import {
  Switch,
  Route
} from "react-router-dom";


function App() {
  const [location, setLocation] = useState();
  const [newLocationSearchActive, setNewLocationSearchActive] = useState(true);
  const [mainNav, setMainNav] = useState("Suche");
  const [locationMetadata, setLocationMetadata] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [place, setPlace] = useState({});

  const toggleNewLocationSearch = () => setNewLocationSearchActive(!newLocationSearchActive);

  const plz = new URLSearchParams(window.location.search).get('plz');

  const suggestionClicked = (location) => {
    setLocation(location);
    setPlace(location);
    setNewLocationSearchActive(false);
  }

  useEffect(() => {
    if( !location ) {
      if (plz) {
        setLocation({zip: plz});
        setNewLocationSearchActive(false);
      }
      return;
    }
    setModalOpen(true);
    fetch(endpoint + "/api/programs?zip=" + location.zip +
      (location.communityId ? "&communityId=" + location.communityId : '') +
      "&format=json")
      .then((data) => data.json())
      .then((data) => {
        setLocationMetadata(data);
        if (data.places.length > 0)
          setPlace(data?.places[0]);
        setModalOpen(false);
      });
  }, [location]);
  
  const endpoint = process.env.REACT_APP_APIHOST;

  const nextProgram = () => {
    setCurrentProgramIndex((currentProgramIndex + 1) % locationMetadata.programs.length);
  }
  const previousProgram = () => {
      setCurrentProgramIndex(currentProgramIndex === 0 ? locationMetadata.programs.length - 1
                               : currentProgramIndex - 1);
  }

  const [currentProgramIndex, setCurrentProgramIndex] = useState(0);
  const [selectedServiceId, setSelectedServiceId] = useState();
  // We need to make sure that deeplinking is only evaluated in the beginning, we can't evaluate here though, because we don't have locationMetadata yet
  const [programAndTypeDeeplinked, setProgramAndTypeDeeplinked] = useState(false);

  return (
      <div className="App dg-body">
        <header className="dg-header" id="dg-header">
          <Nav place={place} location={location} setNewLocationSearchActive={setNewLocationSearchActive} mainNav={mainNav} setMainNav={setMainNav} toggleNewLocationSearch={toggleNewLocationSearch}/>
          { newLocationSearchActive && <FindLocation location={location} suggestionClicked={suggestionClicked} setLocation={setLocation} setPlace={setPlace} endpoint={endpoint} setModalOpen={setModalOpen} setNewLocationSearchActive={setNewLocationSearchActive}/> }
        </header>
        <section id="app-body">
          <TransferModal active={modalOpen}/>
          <Switch>
            <Route exact path="/">
              { location && locationMetadata &&
                <ProgramsView place={place} location={location} locationMetadata={locationMetadata} nextProgram={nextProgram} previousProgram={previousProgram} currentProgramIndex={currentProgramIndex}
                  setCurrentProgramIndex={setCurrentProgramIndex} selectedServiceId={selectedServiceId} setSelectedServiceId={setSelectedServiceId} programAndTypeDeeplinked={programAndTypeDeeplinked}
                  setProgramAndTypeDeeplinked={setProgramAndTypeDeeplinked}
                />}
              { !location && <HelpContent /> }
            </Route>
            <Route path="/hilfe">
              <Help setNewLocationSearchActive={setNewLocationSearchActive}/>
            </Route>
            <Route path="/empfangswege">
              <Empfangswege endpoint={endpoint} setNewLocationSearchActive={setNewLocationSearchActive}/>
            </Route>
          </Switch>
        </section>
        <section id="app-footer"></section>
      </div>
  );
}

export default App;
